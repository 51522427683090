import logo from './logo.svg';
import './App.css';

import React from 'react';

function App() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Hello, Hostinger!</h1>
      <p>Este é um projeto básico de React para testar o deploy na Hostinger.</p>
    </div>
  );
}

export default App;

